import React from 'react';
import {Table, Spinner, SpaceBetween, Header, Alert, Grid, Button} from "@amzn/awsui-components-react";
import './payload-display.css';
import {configureDomain} from "src/auth/config";
import Logo from '../../resources/clipboard.png';
import Collapsible from "src/pages/commons/collapsible";
interface PayloadProps {
    searchResults: any,
    searchId: string
}

export class PayloadDisplay extends React.Component <PayloadProps, {searchId: string}> {
    columns: string[];
    tav: string[];
    constructor(props: any) {
        super(props);
        this.columns = [
            "billing_currency_code", 
            "event_date",
            "fx_rate", 
            "local_currency_code",
            "modification_date", 
            "reseller_id",
            "type", 
            "is_test",
            "eventVersionOverride", 
            "s3Key",
            "s3Bucket",
            "fusenMessageGenerated",
            "fusenMessageArchived", 
            "fusenClientEventGenerated"
            ];
        this.tav = [
            "amount", 
            "billing_amount", 
            "creation_date", 
            "version",
            "tav_modification_date",
            "tav_type"]
        this.state = {
            searchId: this.props.searchId,
        };
    }

    render () {
        const {searchResults, searchId} = this.props;
        const Domain =configureDomain;
        const copyMessage = (val: string) => {
            navigator.clipboard.writeText(val);
        };
        const externalIdLink =((domain) => {
                switch (domain) {
                    case 'prod-FE':
                        return 'https://prod-jp.bart-portal.fuse.amazon.dev/transactionsearch/external_id/';
                    case 'prod-US':
                        return 'https://prod-us.bart-portal.fuse.amazon.dev/transactionsearch/external_id/';
                    case 'prod-EU':
                        return 'https://prod-eu.bart-portal.fuse.amazon.dev/transactionsearch/external_id/';
                }
                return 'https://beta.bart-portal.fuse.amazon.dev/transactionsearch/external_id/';
        })(Domain);
        const subscriptionsLink =((domain) => {
                switch (domain) {
                    case 'prod-FE':
                        return 'https://subscriptions-fe.amazon.com/#/subscription/';
                    case 'prod-US':
                        return 'https://subscriptions-na.amazon.com/#/subscription/';
                    case 'prod-EU':
                        return 'https://subscriptions-eu.amazon.com/#/subscription/';
                }
                return 'https://subscriptions-na.integ.amazon.com/#/subscription/';
        })(Domain);
        const flashUILink = ((domain) => {
                switch (domain) {
                    case 'prod-FE':
                        return 'https://flashui-fe.aka.amazon.com/search/transaction?transactionId=';
                    case 'prod-US':
                        return 'https://flashui-na.aka.amazon.com/search/transaction?transactionId=';
                    case 'prod-EU':
                        return 'https://flashui-eu.aka.amazon.com/search/transaction?transactionId=';
                }
            return 'https://flashui-beta-na.integ.amazon.com/search/transaction?transactionId=';
        })(Domain);
        const rACEUILink = ((domain) => {
            switch (domain) {
                case 'prod-FE':
                    return 'https://race-website-fe.aka.amazon.com/deferral_search/index?deferral-id=';
                case 'prod-US':
                    return 'https://race-website-na.aka.amazon.com/deferral_search/index?deferral-id=';
                case 'prod-EU':
                    return 'https://race-website-eu.aka.amazon.com/deferral_search/index?deferral-id=';
            }
            return 'https://race-website.integ.amazon.com:13743/deferral_search/index?deferral-id=';
        })(Domain);
        const fusePlanIdLink =((domain) => {
            switch (domain) {
                case 'prod-FE':
                    return 'https://prod-jp.bart-portal.fuse.amazon.dev/getplan/';
                case 'prod-US':
                    return 'https://prod-us.bart-portal.fuse.amazon.dev/getplan/';
                case 'prod-EU':
                    return 'https://prod-eu.bart-portal.fuse.amazon.dev/getplan/';
            }
            return 'https://beta.bart-portal.fuse.amazon.dev/getplan/';
        })(Domain);
        const fusePlanIdFenderLink =((domain) => {
            switch (domain) {
                case 'prod-FE':
                    return 'https://fender.corp.amazon.com/domains/prod-FE/plans/';
                case 'prod-US':
                    return 'https://fender.corp.amazon.com/domains/prod-NA/plans/';
                case 'prod-EU':
                    return 'https://fender.corp.amazon.com/domains/prod-EU/plans/';
            }
            return 'https://fender.corp.amazon.com/domains/devo-FE/plans/';
        })(Domain);
        if (searchResults != "init") {
            if (searchResults == "invalid") {
                return (
                    <div>
                        <Grid
                            gridDefinition={[
                                {colspan: {l: 7, xxs: 10}},
                            ]}
                        >
                            <Alert header="No results found!" type="error"></Alert>
                        </Grid>
                    </div>);
            }
            if (searchResults == "error") {
                return (
                    <div>
                        <Grid
                            gridDefinition={[
                                {colspan: {l: 7, xxs: 10}},
                            ]}
                        >
                            <Alert header="Search timed out!" type="error"></Alert>
                        </Grid>
                    </div>);
            }
            const fbtsResponse = Array.isArray(searchResults) ? searchResults : Object.values(searchResults).filter(item => typeof item === 'object');
            let join: boolean = (searchResults["0"]["tav_id"] != null);
            let tav_table = null;
            let source_field = null;
            let source = null;
            let tavHeaderMap = new Map<string, string>([
                ["amount", "amount"],
                ["billing_amount", "billing amount"],
                ["creation_date", "creation date"],
                ["version", "version"],
                ["tav_modification_date", "tav modification date"],
                ["tav_type", "tav type"]
            ]);
            if (join) {
                let tav_columns: any[] = [];
                tav_columns.push({
                    id: "",
                    header: "",
                    cell: () => (""),
                });
                for (let i = 0; i < this.tav.length; i++) {
                    let column = this.tav[i];
                    if (
                        column === "amount" ||
                        column === "billing_amount" ||
                        column === "creation_date" ||
                        column === "version" ||
                        column === "tav_modification_date" ||
                        column === "tav_type"
                    ) {
                        tav_columns.push({
                            id: column,
                            header: tavHeaderMap.get(column),
                            cell: (item: any) => (item[column] ? item[column].toString() : ''),
                            sortingField: column
                        });
                    }
                }
                tav_columns.push({
                    id: "",
                    header: "",
                    cell: () => (""),
                });
                tav_table = 
                (<Table 
                    columnDefinitions = {tav_columns} 
                    items = {join ? [searchResults["0"]] : searchResults}
                    wrapLines = {false} 
                    resizableColumns = {false} 
                    header = {<Header>Transaction Amount Version for Transaction <i>{searchResults["0"]["transaction_id"]}</i></Header>}
                ></Table>);
                source =
                (<Collapsible
                    open = {false}
                    title = {"Full Source for Transaction " + searchResults["0"]["transaction_id"]}
                >
                    <pre className = "sourceInfo ">{JSON.stringify(JSON.parse(searchResults["0"]["source"]), null, 2)}</pre>
                </Collapsible>);

                let source_field_columns: any[] = [];
                source_field_columns.push({
                    id: "",
                    header: "",
                    cell: () => (""),
                });
                if(JSON.parse(searchResults["0"]["source"]).hasOwnProperty("period")) {
                    source_field_columns.push({
                        id: "FusePlanId",
                        header: "fuse plan id",
                        cell: (item: any) => (
                            <div>
                                <a href={fusePlanIdFenderLink+item["period"]["planId"].toString()} target="_blank">{item["period"]["planId"].toString()}</a>
                                <a href={fusePlanIdLink+item["period"]["planId"].toString()} target="_blank"><Button>BART</Button></a>
                            </div>
                        ),
                    });
                };
                if(JSON.parse(searchResults["0"]["source"]).hasOwnProperty("subscriptionUpdateMessage")) {
                    source_field_columns.push({
                        id: "FuseSubsId",
                        header: "fuse subs id",
                        cell: (item:any) => (item["subscriptionUpdateMessage"]["current"]["subscription"]["planID"])
                    });
                };
                source_field_columns.push({
                    id: "Raga Enabled",
                    header: "raga enabled",
                    cell: (item: any) => ("ragaControlParameters" in item ? "true" : "false")
                });
                source_field_columns.push({
                    id: "Test Transaction",
                    header: "test transaction",
                    cell: (item:any) => ((searchResults["0"]["is_test"]).toString())
                });
                if (JSON.parse(searchResults["0"]["source"]).hasOwnProperty("ragaControlParameters")) {
                    source_field_columns.push({
                        id: "Raga Enabled",
                        header: "raga enabled",
                        cell: (item:any) => ("ragaControlParameters" in item? "true": "false")
                    });
                    source_field_columns.push({
                        id: "term",
                        header: "term",
                        cell: (item:any) => (item["ragaControlParameters"]["term"])
                    });
                    source_field_columns.push({
                        id: "product",
                        header: "product",
                        cell: (item:any) => (item["ragaControlParameters"]["product"])
                    });
                    source_field_columns.push({
                        id: "glLineDescriptionPartnerName",
                        header: "gl line description partner name",
                        cell: (item:any) => (item["ragaControlParameters"]["glLineDescriptionPartnerName"])
                    });
                    source_field_columns.push({
                        id: "fuseClientId",
                        header: "fuse client id",
                        cell: (item:any) => (item["ragaControlParameters"]["fuseClientId"])
                    });
                    source_field_columns.push({
                        id: "businessCountryCode",
                        header: "business country code",
                        cell: (item:any) => (item["ragaControlParameters"]["businessCountryCode"])
                    });
                    source_field_columns.push({
                        id: "buyingCustomerCountryCode",
                        header: "buying customer country code",
                        cell: (item:any) => (item["ragaControlParameters"]["buyingCustomerCountryCode"])
                    });
                    source_field_columns.push({
                        id: "accountingTemplate",
                        header: "accounting template",
                        cell: (item:any) => (item["ragaControlParameters"]["accountingTemplate"])
                    });
                    source_field_columns.push({
                        id: "fusenEnabled",
                        header: "fusen enabled",
                        cell: (item:any) => (item["ragaControlParameters"]["fusenEnabled"].toString())
                    });
                    source_field_columns.push({
                        id: "fusenEnabledDate",
                        header: "fusen enabled date",
                        cell: (item:any) => (item["ragaControlParameters"]["fusenEnabledDate"])
                    });
                    source_field_columns.push({
                        id: "migrationTargetStage",
                        header: "migration target stage",
                        cell: (item:any) => (item["ragaControlParameters"]["migrationTargetStage"])
                    });
                }
                source_field_columns.push({
                    id: "",
                    header: "",
                    cell: () => (""),
                });
                source_field = (<Table 
                    columnDefinitions = {source_field_columns} 
                    items = {[JSON.parse(searchResults["0"]["source"])]}
                    wrapLines = {false} 
                    resizableColumns = {false} 
                    header = {<Header>Important Source Fields for Transaction <i>{searchResults["0"]["transaction_id"]}</i></Header>}
                ></Table>);
            }
            let columnDefinitions: any[] = [];
            columnDefinitions.push({
                id: "",
                header: "",
                cell: () => (""),
            });
            columnDefinitions.push({
                id: "external_id",
                header: "external id",
                cell: (item: any) => (
                    <a href={externalIdLink+item["external_id"].toString()} target="_blank">{item["external_id"].toString()}</a>
                ),
            });
            columnDefinitions.push({
                id: "event_type",
                header: "event type",
                cell: (item: any) => (item["external_id"].toString().split(".")[1]),
            });
            columnDefinitions.push({
                id: "transaction_id",
                header: "transaction id",
                cell: (item: any) => (
                    <div>
                        {item["transaction_id"].toString()}
                        <Button onClick={() => copyMessage(item["transaction_id"].toString())}><img src={Logo} width={10}/></Button>
                    </div>
                ),
            });
            let columnDefinitionsHeaderMap = new Map<string, string>([
                ["billing_currency_code", "billing currency code"],
                ["event_date", "event date"],
                ["fx_rate", "fx rate"],
                ["local_currency_code", "local currency code"],
                ["modification_date", "modification date"],
                ["reseller_id", "reseller id"],
                ["type", "type"],
                ["is_test", "is test"]
            ]);
            for (let i = 0; i < this.columns.length; i++) {
                let column = this.columns[i];
                if (
                    column === "billing_currency_code" ||
                    column === "event_date" ||
                    column === "fx_rate" ||
                    column === "local_currency_code" ||
                    column === "modification_date" ||
                    column === "reseller_id"  ||
                    column === "type"  ||
                    column === "is_test"
                ) {
                    columnDefinitions.push({
                        id: column,
                        header: columnDefinitionsHeaderMap.get(column),
                        cell: (item: any) => (item[column] ? item[column].toString() : ''),
                        sortingField: column
                    });
                }
            }
            // Add transactions links here
            columnDefinitions.push({
                id: "Subs  link",
                header: "subs link",
                cell: (item: any) => (
                  <a href={subscriptionsLink+item["external_id"].toString().split("-")[0]} target="_blank">{item["external_id"].toString().split("-")[0]}</a>
                ),
            });
            columnDefinitions.push({
                id: "Flash  link",
                header: "flash link",
                cell: (item: any) => (
                  <a href={flashUILink+item["external_id"].toString().split(".")[0]} target="_blank">{item["external_id"].toString().split(".")[0]}</a>
                ),
            });
            columnDefinitions.push({
                id: "rACE  link",
                header: "rACE link",
                cell: (item: any) => (
                  <a href={rACEUILink+item["external_id"].toString().split(".")[0]} target="_blank">{item["external_id"].toString().split(".")[0]}</a>
                ),
            });
            columnDefinitions.push({
                id: "",
                header: "",
                cell: () => (""),
            });
            let table = 
                (<Table 
                    columnDefinitions = {columnDefinitions}
                    items={Array.isArray(fbtsResponse) ? fbtsResponse : [fbtsResponse]}
                    wrapLines = {false} 
                    resizableColumns = {false}
                ></Table>);

                let newColumnDefinitions = [];
                newColumnDefinitions.push({
                    id: "",
                    header: "",
                    cell: () => (""),
                });
                let newColumnDefinitionsHeaderMap = new Map([
                    ["eventVersionOverride", "Event Version Override"],
                    ["s3Key", "S3 Key"],
                    ["s3Bucket", "S3 Bucket"],
                    ["fusenMessageGenerated", "Fusen Message Generated"],
                    ["fusenMessageArchived", "Fusen Message Archived"],
                    ["fusenClientEventGenerated", "Fusen Client Event Generated"]
                ]);
                
                for (let i = 0; i < this.columns.length; i++) {
                    let column = this.columns[i];
                    if (
                        column === "eventVersionOverride" ||
                        column === "s3Key" ||
                        column === "s3Bucket" ||
                        column === "fusenMessageGenerated" ||
                        column === "fusenMessageArchived" ||
                        column === "fusenClientEventGenerated"
                    ) {
                        newColumnDefinitions.push({
                            id: column,
                            header: newColumnDefinitionsHeaderMap.get(column),
                            cell: (item: any) => {
                                const ftsResponse = searchResults.ftsResponse;
                                if (ftsResponse && ftsResponse.result && ftsResponse.result.length > 0) {
                                    const result = ftsResponse.result[0];
                                    const value = result[column === "s3Key" ? "sourceS3Key" : column === "s3Bucket" ? "sourceS3Bucket" : column];
                                    return value !== null && value !== undefined ? value.toString() : "-";
                                }
                                return "-";
                            },
                            sortingField: column
                        });
                    }
                }
                newColumnDefinitions.push({
                    id: "",
                    header: "",
                    cell: () => (""),
                });
                let newTable = (
                    <Table
                        columnDefinitions={newColumnDefinitions}
                        items={Array.isArray(fbtsResponse) ? fbtsResponse : [fbtsResponse]}
                        wrapLines={false}
                        resizableColumns={false}
                        header={<Header>FUSEN Tracking Details</Header>}
                    />
                );
            
                    

            return (
                <div style={{paddingLeft: '10px'}}>
                    <SpaceBetween size = "l">
                        {table}
                        {newTable}
                        {tav_table}
                        {source_field}
                        {source}
                    </SpaceBetween>
                </div>
            );
        } else {
            return <Spinner size = "large"></Spinner>;
        }
    }
}

export default PayloadDisplay;
